import { FC, useContext, useEffect } from 'react';
import { AuthProfileContext } from '../contexts/AuthProfileContext';
import { NavigateFunction, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import SettingsService from '../services/SettingsService';
import { SettingsType } from '../models/SettingsType';
import { box, footer } from '../models/defaultStyle';
import { Box } from '@mui/material';
import '@fontsource/poppins';
import AllowedCallbacksService from '../services/AllowedCallbacksService';
import { CustomError } from '../models/CustomError';
import { API_HOST } from '../models/Properties';

const parameters = {
  apiHost: 'apiHost',
  authProfile: 'authProfile',
  workspace: 'workspace',
  redirectURI: 'redirectURI',
  environment: 'environment',
};

const AppContainerPage: FC = () => {
  const navigate = useNavigate();
  const {
    setApiHost,
    setAuthProfileID,
    authProfileID,
    setEnvironment,
    setWorkspaceID,
    setStyle,
    setError,
  } = useContext(AuthProfileContext);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const fetchData = async () => {
      await SettingsService.loadDefaults({
        apiHost: searchParams.get(parameters.apiHost) || API_HOST,
        profileId: searchParams.get(parameters.authProfile) || '',
        workspaceId: searchParams.get(parameters.workspace) || '',
        environment: searchParams.get(parameters.environment) || '',
      });
      setApiHost(SettingsService.get(SettingsType.API_HOST));
      setWorkspaceID(SettingsService.get(SettingsType.WORKSPACE_ID));
      setAuthProfileID(SettingsService.get(SettingsType.PROFILE_ID));
      setEnvironment(SettingsService.get(SettingsType.ENVIRONMENT));
      const callBackURL = searchParams.get(parameters.redirectURI) || '';
      SettingsService.set(SettingsType.LOGIN_URL_CALLBACK, callBackURL);
      setStyle(SettingsService.get(SettingsType.STYLE_DEFINITION));
      document.body.style.backgroundImage = JSON.parse(
        SettingsService.get(SettingsType.STYLE_DEFINITION),
      ).commons.background.backgroundImage;
      document.body.style.backgroundSize = JSON.parse(
        SettingsService.get(SettingsType.STYLE_DEFINITION),
      ).commons.background.backgroundSize;

      if (SettingsService.getExistLoggin()) {
        window.location.replace(SettingsService.getExistLoggin());
      }

      await validateAllowedCallbacks(callBackURL, setError, navigate);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <Box style={box}>{authProfileID && <Outlet />}</Box>
      <section style={footer}>Powered by 8base</section>
    </>
  );
};

export default AppContainerPage;
async function validateAllowedCallbacks(
  callBackURL: string,
  setError: (values: CustomError) => void,
  navigate: NavigateFunction,
) {
  try {
    const isAllowedCallbackURL: boolean = await AllowedCallbacksService.isAllowedCallback({
      callbackURL: callBackURL,
      apiHost: SettingsService.get(SettingsType.API_HOST),
      profileId: SettingsService.get(SettingsType.PROFILE_ID),
      workspaceId: SettingsService.get(SettingsType.WORKSPACE_ID),
      environment: SettingsService.get(SettingsType.ENVIRONMENT),
    });

    if (!isAllowedCallbackURL) {
      setError({
        title: 'Callback URL is not Allowed',
        message: 'Please verify your callback url ' + callBackURL,
      });
      navigate('/error', { replace: true });
    }
  } catch (e) {
    const err = e as CustomError;
    setError({ title: err.title, message: err.message });
    navigate('/error', { replace: true });
  }
}
