import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { API_HOST } from './Properties';

let workspaceIdUsed = '';
let apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri: API_HOST + '/' + workspaceIdUsed + '/',
  cache: new InMemoryCache(),
});

export function getApolloClient(apiHost: string, workspaceId: string, environment: string) {
  console.log('valor de environment ' + environment);
  if (workspaceIdUsed != workspaceId) {
    let uri = '';
    if (environment) {
      uri = apiHost + '/' + workspaceId + '_' + environment + '/';
    } else {
      uri = apiHost + '/' + workspaceId + '/';
    }

    console.log('using ' + uri);
    apolloClient = new ApolloClient({
      uri: uri,
      cache: new InMemoryCache(),
    });
    workspaceIdUsed = workspaceId;
  }
  return apolloClient;
}
