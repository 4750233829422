import { ISettingsBase } from '../models/ISettingsBase';
import { SettingsType } from '../models/SettingsType';
import StyleService from './StyleService';
export const SESSION = 'session';

export default class SettingsService {
  public static get(type: SettingsType): string {
    return sessionStorage.getItem(SettingsType[type]) || '';
  }
  public static set(type: SettingsType, value: string) {
    return sessionStorage.setItem(SettingsType[type], value);
  }

  public static async loadDefaults(params: ISettingsBase) {
    this.set(SettingsType.API_HOST, params.apiHost);
    this.set(SettingsType.PROFILE_ID, params.profileId);
    this.set(SettingsType.WORKSPACE_ID, params.workspaceId);
    this.set(SettingsType.ENVIRONMENT, params.environment);
    this.set(SettingsType.STYLE_DEFINITION, await StyleService.get(params));
  }
  private static getSessionKey(): string {
    return (
      SESSION +
      this.get(SettingsType.ENVIRONMENT) +
      this.get(SettingsType.WORKSPACE_ID) +
      this.get(SettingsType.PROFILE_ID)
    );
  }

  public static getExistLoggin() {
    const value = localStorage.getItem(this.getSessionKey()) || undefined;
    if (value) {
      const item = JSON.parse(value);
      const now = new Date();
      const expirationDate = new Date(item.expiry * 1000);
      if (now > expirationDate) {
        localStorage.removeItem(SESSION);
      } else {
        return item.callbackUrl;
      }
    }
    return '';
  }

  public static setLoginSucessful(user: string, callbackUrl: string, expirationTime: number) {
    const item = {
      user: user,
      callbackUrl: callbackUrl,
      expiry: expirationTime,
    };
    localStorage.setItem(this.getSessionKey(), JSON.stringify(item));
  }
}
