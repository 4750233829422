import { Form, Formik, FormikProps } from 'formik';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { card, cardActions, cardContent } from '../../models/defaultStyle';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';
import CustomErrorMessage from '../commons/components/CustomErrorMessage';
import { routes } from '../routes';
import PasswordValidation from './PasswordValidation';
import { SignupValues } from './Signup';
import { passwordValidators } from './Utils';
import { INVALID_EMAIL_ADDRESS_ERROR } from '../../models/Constants';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type SignupFormikValues = {
  onSubmit: (formValues: SignupValues) => Promise<void>;
  disableSubmit?: boolean;
};

const SignupForm: FC<SignupFormikValues> = ({ onSubmit, disableSubmit = false }) => {
  const [showPassword, setShowPassword] = useState(false);

  const { style, error } = useContext(AuthProfileContext);
  const styleSection = JSON.parse(style).signupPage;
  const commonStyleSection = JSON.parse(style).commons;

  const validateForm = Yup.object().shape({
    password: Yup.string()
      .required('Required')
      .min(passwordValidators.min, 'Password must contain at least 8 characters')
      .matches(passwordValidators.number, ' Password must contain a number')
      .matches(passwordValidators.lowerCase, 'Password must contain a lower case letter')
      .matches(passwordValidators.spaces, 'Password must not contain a leading or trailing space')
      .matches(passwordValidators.upperCase, 'Password must contain an upper case letter'),
    name: Yup.string().required('Required'),
    familyName: Yup.string().required('Required'),
    email: Yup.string().email(INVALID_EMAIL_ADDRESS_ERROR).required('Required'),
  });

  const initialValues: SignupValues = {
    email: '',
    password: '',
    name: '',
    familyName: '',
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validateForm}>
      {({ values, errors, touched, handleChange, handleBlur }: FormikProps<SignupValues>) => (
        <Card style={card}>
          <section style={commonStyleSection.logo.container}>
            <img
              alt="Company logo"
              style={commonStyleSection.logo.img}
              src={commonStyleSection.logo.url}
            ></img>
          </section>

          <section style={styleSection.card.title}>Create your account</section>
          <section style={styleSection.card.subtitle}>It’s free. No credit card required.</section>
          <Form>
            <CardContent style={cardContent}>
              <TextField
                style={styleSection.card.form.field}
                fullWidth
                id="email"
                name="email"
                label="Email Address"
                disabled={disableSubmit}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                style={styleSection.card.form.field}
                fullWidth
                id="name"
                name="name"
                label="First Name"
                disabled={disableSubmit}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                style={styleSection.card.form.field}
                fullWidth
                id="familyName"
                name="familyName"
                label="Last Name"
                disabled={disableSubmit}
                value={values.familyName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.familyName && Boolean(errors.familyName)}
                helperText={touched.familyName && errors.familyName}
              />
              <TextField
                style={styleSection.card.form.field}
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                disabled={disableSubmit}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password === 'Required' && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <PasswordValidation password={values.password} />
            </CardContent>
            {error.title && (
              <CardContent style={cardContent}>
                <CustomErrorMessage
                  title={error.title}
                  message={error.message}
                ></CustomErrorMessage>
              </CardContent>
            )}
            <CardActions style={cardActions}>
              <Button
                disabled={disableSubmit}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                style={cardActions.button}
              >
                Sign Up
              </Button>
            </CardActions>
          </Form>
          <section style={commonStyleSection.redirectText.container}>
            <span>Have an account?</span>
            <Link style={commonStyleSection.redirectText.link} to={routes.login}>
              Log in
            </Link>
          </section>
        </Card>
      )}
    </Formik>
  );
};

export default SignupForm;
