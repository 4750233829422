export const INVALID_EMAIL_ADDRESS_ERROR = 'Invalid email address';

export const DEFAULT_MESSAGE_REMEMBER = {
  title: 'Incorrect email',
  message:
    'Review your email and try again. If you are unable to access your account please Contact Support',
};

export const DEFAULT_MESSAGE_FORGOT_PASSWORD = {
  title: 'Incorrect code',
  message:
    'Review your email and try again. If you are unable to access your account please Contact Support',
};
