import { createContext } from 'react';

export type rememberContextType = {
  isResend: boolean;
  setIsResend: (value: boolean) => void;
  email: string;
  setEmail: (value: string) => void;
};

const defaultRememberContext: rememberContextType = {
  isResend: false,
  setIsResend: () => {},
  email: '',
  setEmail: () => {},
};

export const RememberContext = createContext(defaultRememberContext);
