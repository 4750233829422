import { FC } from 'react';
import { PasswordValidators as style } from '../../models/defaultStyle';
import { CheckCircle, ErrorOutlineOutlined } from '@mui/icons-material';

type passwordCheckProps = {
  text: string;
  check: boolean;
};
const PasswordCheck: FC<passwordCheckProps> = ({ text, check }) => {
  const optionStyle = check ? style.checkPass : style.checkFail;
  return (
    <div style={{ ...style.option, ...optionStyle }}>
      {check && <CheckCircle style={style.icon} />}
      {!check && <ErrorOutlineOutlined style={style.icon} />}
      {text}
    </div>
  );
};

export default PasswordCheck;
