import { CustomError } from '../models/CustomError';
import { Auth } from '../models/ILoginService';
import { ISettingsBase } from '../models/ISettingsBase';

export class ServiceBase {
  protected static validateSettingsBaseInformation(
    params: ISettingsBase,
    reject: (reason?: CustomError) => void,
  ) {
    if (!params) {
      reject(createMessage('WORKSPACE AND PROFILE NOT FOUND'));
    }
    if (!params.profileId) {
      reject(createMessage('PROFILE_ID NOT FOUND'));
    }
    if (!params.workspaceId) {
      reject(createMessage('WORKSPACE_ID NOT FOUND'));
    }
  }
  static composeRedirect(token: Auth, callbackUrl: string): string {
    return (
      callbackUrl +
      '/#id_token=' +
      token.idToken +
      '&id_token_expires_at=' +
      token.idTokenExpiresAt +
      '&access_token=' +
      token.accessToken +
      '&access_token_expires_at=' +
      token.accessTokenExpiresAt +
      '&refresh_token=' +
      token.refreshToken +
      '&expires_in=3600&token_type=Bearer&state=STATE'
    );
  }
}
function createMessage(message: string): CustomError {
  return {
    title: 'Bad Configuration',
    message: message,
  };
}
