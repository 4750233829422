import { useContext, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { cardActions, cardContent, card, linkOnBottom } from '../../models/defaultStyle';
import { loginFormValues } from '../../models/Loginform';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';
import CustomErrorMessage from '../commons/components/CustomErrorMessage';
import { routes } from '../routes';
import { INVALID_EMAIL_ADDRESS_ERROR } from '../../models/Constants';

const validateForm = Yup.object().shape({
  password: Yup.string().required('Required'),
  email: Yup.string().email(INVALID_EMAIL_ADDRESS_ERROR).required('Required'),
});

type loginFormType = FormikProps<loginFormValues> & { disableSubmit: boolean };

const LoginForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  disableSubmit,
}: loginFormType) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { error } = useContext(AuthProfileContext);
  return (
    <Form>
      <CardContent style={cardContent}>
        <TextField
          style={values.style.loginPage.card.form.field}
          fullWidth
          id="email"
          name="email"
          label="Email Address"
          disabled={disableSubmit}
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />

        <TextField
          fullWidth
          style={values.style.loginPage.card.form.field}
          id="password"
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          disabled={disableSubmit}
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </CardContent>
      {error.title && (
        <CardContent style={cardContent}>
          <CustomErrorMessage title={error.title} message={error.message}></CustomErrorMessage>
        </CardContent>
      )}
      <CardActions style={linkOnBottom}>
        <section style={values.style.commons.redirectText.container}>
          <Link style={values.style.commons.redirectText.link} to={routes.remember}>
            Forgot your password?
          </Link>
        </section>
      </CardActions>

      <CardActions style={cardActions}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={disableSubmit}
          style={cardActions.button}
        >
          Log In
        </Button>
      </CardActions>
    </Form>
  );
};

export function GetForm(
  initialValues: loginFormValues,
  onSubmit: (formValues: loginFormValues) => Promise<void>,
  disableSubmit: boolean,
) {
  return (
    <Card style={card}>
      <section style={initialValues.style.commons.logo.container}>
        <img
          alt="Company logo"
          style={initialValues.style.commons.logo.img}
          src={initialValues.style.commons.logo.url}
        ></img>
      </section>

      <section style={initialValues.style.loginPage.card.title}>Welcome</section>
      <section style={initialValues.style.loginPage.card.subtitle}>Log in to your account</section>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validateForm}>
        {props => <LoginForm {...props} disableSubmit={disableSubmit} />}
      </Formik>
      <section style={initialValues.style.commons.redirectText.container}>
        <span>New User?</span>
        <Link style={initialValues.style.commons.redirectText.link} to={routes.signup}>
          Create Your Account
        </Link>
      </section>
    </Card>
  );
}
