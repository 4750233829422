import { IStyleServiceRequest } from '../models/IStyleService';
import { ServiceBase } from './ServiceBase';

const defaultStyle = {
  commons: {
    background: {
      backgroundImage: `url(https://staticpublicresources.s3.amazonaws.com/8baseauth/8basebackgroundbg%402x.jpg)`,
      backgroundSize: 'cover',
    },
    logo: {
      container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1.5rem',
      },
      img: {
        height: '56px',
        width: '56px',
      },
      icon: {
        color: '#06C27D',
        height: '56px',
        width: '56px',
      },
      url: 'https://staticpublicresources.s3.amazonaws.com/8baseauth/8baselogo192.png',
    },
    redirectText: {
      container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Poppins',
        gap: 6,
      },
      link: {
        color: '#0973f8',
        fontFamily: 'Poppins',
        textDecoration: 'none',
      },
    },
  },
  loginPage: {
    card: {
      title: {
        color: '#323C47',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 'x-large',
        lineHeight: '24px',
        textAlign: 'center',
        margin: 'auto',
      },
      subtitle: {
        color: '#323C47',
        fontFamily: 'Poppins',
        letterSpacing: '0',
        lineHeight: '20px',
        textAlign: 'center',
        margin: 'auto',
        paddingTop: '0.5rem',
      },
      form: {
        field: {
          marginTop: '1rem',
          fontFamily: 'Poppins',
        },
      },
    },
  },
  signupPage: {
    card: {
      title: {
        color: '#323C47',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 'x-large',
        lineHeight: '24px',
        textAlign: 'center',
        margin: 'auto',
      },
      subtitle: {
        color: '#323C47',
        fontFamily: 'Poppins',
        letterSpacing: '0',
        lineHeight: '20px',
        textAlign: 'center',
        margin: 'auto',
        paddingTop: '0.5rem',
      },
      form: {
        field: {
          marginTop: '1rem',
          fontFamily: 'Poppins',
        },
      },
    },
  },
};
export default class StyleService extends ServiceBase {
  public static get = async (params: IStyleServiceRequest): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      super.validateSettingsBaseInformation(params, reject);
      resolve(JSON.stringify(defaultStyle)); //TODO: implement call to server service
    });
  };
}
