import { CustomError } from '../models/CustomError';
import { ILoginServiceRequest, ILoginServiceResponse } from '../models/ILoginService';
import { ServiceBase } from './ServiceBase';
import { gql } from '@apollo/client';
import { getApolloClient } from '../models/ApolloClient';

const USER_LOGIN_MUTATION = gql`
  mutation UserLogin($email: String!, $password: String!, $authProfileId: ID!) {
    userLogin(data: { email: $email, password: $password, authProfileId: $authProfileId }) {
      auth {
        idToken
        refreshToken
        accessToken
        accessTokenExpiresAt
        idTokenExpiresAt
      }
    }
  }
`;

export default class LoginService extends ServiceBase {
  static DefaultMessage = {
    title: 'Incorrect email or password',
    message:
      'Review your email and password and try again. If you are unable to access your account please contact Support',
  };

  public static login = async (params: ILoginServiceRequest): Promise<ILoginServiceResponse> => {
    return new Promise<ILoginServiceResponse>((resolve, reject) => {
      LoginService.validateBeforeCallLoginOnServer(params, reject);
      LoginService.callLoginOnServer(params, resolve, reject);
    });
  };

  private static callLoginOnServer(
    params: ILoginServiceRequest,
    resolve: (value: ILoginServiceResponse | PromiseLike<ILoginServiceResponse>) => void,
    reject: (reason?: CustomError) => void,
  ) {
    return getApolloClient(params.apiHost, params.workspaceId, params.environment)
      .mutate({
        mutation: USER_LOGIN_MUTATION,
        variables: {
          email: params.userName,
          password: params.password,
          authProfileId: params.profileId,
        },
      })
      .then(response => {
        resolve(response.data.userLogin.auth);
      })
      .catch(error => {
        console.error(error);
        reject(this.DefaultMessage);
      });
  }

  private static validateBeforeCallLoginOnServer(
    params: ILoginServiceRequest,
    reject: (reason?: CustomError) => void,
  ) {
    super.validateSettingsBaseInformation(params, reject);

    if (!params.userName || !params.password) {
      reject(this.DefaultMessage);
    }
  }
}
