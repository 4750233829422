import { ServiceBase } from './ServiceBase';
import { ISignupService } from '../models/ISignupService';
import { CustomError } from '../models/CustomError';
import { gql } from '@apollo/client';
import { getApolloClient } from '../models/ApolloClient';

const USER_SIGN_UP_WITH_PASSWORD_MUTATION = gql`
  mutation UserSignUpWithPassword(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $authProfileId: ID!
  ) {
    userSignUpWithPassword(
      user: { email: $email, firstName: $firstName, lastName: $lastName }
      authProfileId: $authProfileId
      password: $password
    ) {
      id
    }
  }
`;

export default class SignupService extends ServiceBase {
  static DefaultMessage = {
    title: 'Server error',
    message: 'Check your information; please contact support if you think this is a mistake.',
  };
  public static signup = async (params: ISignupService): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      SignupService.validateBeforeCallSignupOnServer(params, reject);
      SignupService.callSignupOnServer(params, resolve, reject);
    });
  };

  private static validateBeforeCallSignupOnServer(
    params: ISignupService,
    reject: (reason?: CustomError) => void,
  ) {
    super.validateSettingsBaseInformation(params, reject);
    if (!params.userName || !params.password) {
      reject(this.DefaultMessage);
    }
  }

  private static callSignupOnServer(
    params: ISignupService,
    resolve: (value: string | PromiseLike<string>) => void,
    reject: (reason?: CustomError) => void,
  ) {
    return getApolloClient(params.apiHost, params.workspaceId, params.environment)
      .mutate({
        mutation: USER_SIGN_UP_WITH_PASSWORD_MUTATION,
        variables: {
          email: params.userName,
          password: params.password,
          authProfileId: params.profileId,
          firstName: params.name,
          lastName: params.familyName,
        },
      })
      .then(response => {
        resolve(response.data.userSignUpWithPassword.id);
      })
      .catch(error => {
        console.error(error);
        reject({
          title: error.message,
          message: 'Check your information; please contact support if you think this is a mistake.',
        });
      });
  }
}
