import { CSSProperties } from 'react';

export const footer: CSSProperties = {
  fontFamily: 'Poppins',
  display: 'flex',
  justifyContent: 'center',
  color: 'rgb(255, 255, 255)',
  fontSize: '12px',
  bottom: '9px',
  left: '47%',
  position: 'fixed',
};

export const box: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '90vh',
  padding: '2rem',
};

export const card: CSSProperties = {
  width: '469px',
  padding: '2rem',
  borderRadius: 10,
};

export const cardContent: CSSProperties = {
  padding: 16,
};

export const linkOnBottom: CSSProperties = {
  paddingLeft: '16px',
};
export const cardActions = {
  padding: 16,
  fontFamily: 'Poppins',
  button: { height: '48px' },
};

type errorMessageStylerType = {
  title: CSSProperties;
  message: CSSProperties;
  link: CSSProperties;
  container: CSSProperties;
};

export const ErrorMessage: errorMessageStylerType = {
  title: {
    color: '#eb4235',
    marginTop: '0',
    display: 'flex',
    gap: '5px',
    fontFamily: 'Poppins',
    fontSize: 'small',
  },
  message: {
    color: '#323c47',
    marginBottom: '0',
    fontFamily: 'Poppins',
    fontSize: 'small',
  },
  link: {
    color: '#0973f8',
  },
  container: {
    border: '1px solid #eb4235',
    padding: '16px',
    borderRadius: '4px',
    backgroundColor: '#ffedeb',
  },
};

type passwordValidatorsStylerType = {
  container: CSSProperties;
  option: CSSProperties;
  titleSection: CSSProperties;
  checkPass: CSSProperties;
  checkFail: CSSProperties;
  icon: CSSProperties;
};
export const PasswordValidators: passwordValidatorsStylerType = {
  container: { fontFamily: 'Poppins', marginTop: 16 },
  option: { gap: 6, display: 'flex', marginTop: 6, alignItems: 'center', fontSize: 'small' },
  titleSection: { marginTop: 16, marginBottom: 12, fontSize: 'smaller' },
  checkPass: { color: '#06C27D' },
  checkFail: { color: '#EB4235' },
  icon: { fontSize: '1.2rem' },
};

export const resendCard = {
  cardContent: {
    textAlign: 'center' as const,
    margin: 'auto',
  },
  message: {
    color: '#323C47',
    fontFamily: 'Poppins',
    letterSpacing: '0',
    lineHeight: '20px',
    margin: 'auto',
    paddingTop: '24px',
  },
  alert: {
    text: {
      padding: '1rem',
      color: '#EB4235',
      fontFamily: 'Poppins',
    },
    container: {
      textAlign: 'center' as const,
      margin: 'auto',
      padding: '16px',
    },
  },
};
