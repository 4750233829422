import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmPasswordForm from './ConfirmPasswordForm';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';
import { getApolloClient } from '../../models/ApolloClient';
import { CustomError } from '../../models/CustomError';
import RememberService from '../../services/RememberService';
import { RememberContext } from '../../contexts/RememberContext';
import { routes } from '../routes';

export type ConfirmPasswordValues = {
  password: string;
  confirmPassword: string;
  code: string;
};

const ConfirmPassword: FC = () => {
  const { apiHost, authProfileID, workspaceID, environment, setError } =
    useContext(AuthProfileContext);
  const { email } = useContext(RememberContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setError({
      title: '',
      message: '',
    });
    getApolloClient(apiHost, workspaceID, environment);
  }, []);

  const onSubmit = async (formValues: ConfirmPasswordValues) => {
    try {
      setLoading(true);
      setError({ title: '', message: '' });
      await RememberService.updatePassword({
        apiHost: apiHost,
        email: email,
        profileId: authProfileID,
        workspaceId: workspaceID,
        environment: environment,
        password: formValues.password,
        code: formValues.code,
      });
      navigate(routes.login);
    } catch (e) {
      const err = e as CustomError;
      setError({ title: err.title, message: err.message });
    } finally {
      setLoading(false);
    }
  };

  return <ConfirmPasswordForm onSubmit={onSubmit} disableSubmit={loading} />;
};

export default ConfirmPassword;
