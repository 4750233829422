import { Card } from '@mui/material';
import { FC, useContext } from 'react';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';

import { card } from '../../models/defaultStyle';

const Error: FC = () => {
  const { style, error } = useContext(AuthProfileContext);
  const currentStyle = JSON.parse(style);
  return (
    <Card style={card}>
      <section style={currentStyle.commons.logo.container}>
        <img
          alt="8Base"
          style={currentStyle.commons.logo.img}
          src={currentStyle.commons.logo.url}
        ></img>
      </section>

      <section style={currentStyle.loginPage.card.title}>{error.title}</section>
      <section style={currentStyle.loginPage.card.subtitle}>{error.message}</section>
    </Card>
  );
};

export default Error;
