import { FC, useContext, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { routes } from '../routes';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { card, cardActions, cardContent } from '../../models/defaultStyle';
import PasswordValidation from '../signup/PasswordValidation';
import CustomErrorMessage from '../commons/components/CustomErrorMessage';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';
import { passwordValidators } from '../signup/Utils';
import { ConfirmPasswordValues } from './ConfirmPassword';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type ConfirmPasswordFormikValues = {
  onSubmit: (formValues: ConfirmPasswordValues) => Promise<void>;
  disableSubmit?: boolean;
};

const ConfirmPasswordForm: FC<ConfirmPasswordFormikValues> = ({
  onSubmit,
  disableSubmit = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const { style, error } = useContext(AuthProfileContext);
  const styleSection = JSON.parse(style).signupPage;
  const commonStyleSection = JSON.parse(style).commons;

  const validateForm = Yup.object().shape({
    code: Yup.string().required('Required'),
    password: Yup.string()
      .required('Required')
      .min(passwordValidators.min, 'Password must contain at least 8 characters')
      .matches(passwordValidators.number, ' Password must contain a number')
      .matches(passwordValidators.lowerCase, 'Password must contain a lower case letter')
      .matches(passwordValidators.spaces, 'Password must not contain a leading or trailing space')
      .matches(passwordValidators.upperCase, 'Password must contain an upper case letter'),
    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const initialValues: ConfirmPasswordValues = {
    password: '',
    confirmPassword: '',
    code: '',
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validateForm}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }: FormikProps<ConfirmPasswordValues>) => (
        <Card style={card}>
          <section style={commonStyleSection.logo.container}>
            <img
              alt="Company logo"
              style={commonStyleSection.logo.img}
              src={commonStyleSection.logo.url}
            ></img>
          </section>

          <section style={styleSection.card.title}>Reset Your Password</section>
          <section style={styleSection.card.subtitle}>
            We have sent a password reset code to the email provided by you. Please enter it below
            to reset your password.
          </section>
          <Form>
            <CardContent style={cardContent}>
              <TextField
                style={styleSection.card.form.field}
                fullWidth
                id="code"
                name="code"
                label="Code"
                disabled={disableSubmit}
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code && errors.code}
              />

              <TextField
                style={styleSection.card.form.field}
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                disabled={disableSubmit}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password === 'Required' && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                style={styleSection.card.form.field}
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm password"
                type={showPasswordConfirm ? 'text' : 'password'}
                disabled={disableSubmit}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.confirmPassword &&
                  errors.confirmPassword === 'Required' &&
                  Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPasswordConfirm}
                      >
                        {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <PasswordValidation
                password={values.password}
                confirmPassword={values.confirmPassword}
                showExtraValidation
              />
            </CardContent>
            {error.title && (
              <CardContent style={cardContent}>
                <CustomErrorMessage
                  title={error.title}
                  message={error.message}
                ></CustomErrorMessage>
              </CardContent>
            )}
            <CardActions style={cardActions}>
              <Button
                disabled={disableSubmit}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                style={cardActions.button}
              >
                Set New Password
              </Button>
            </CardActions>
          </Form>
          <section style={commonStyleSection.redirectText.container}>
            <Link style={commonStyleSection.redirectText.link} to={routes.login}>
              Go back to login screen
            </Link>
          </section>
        </Card>
      )}
    </Formik>
  );
};

export default ConfirmPasswordForm;
