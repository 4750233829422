import { Paper } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { CustomError } from '../../../models/CustomError';
import { FC } from 'react';
import { ErrorMessage as style } from '../../../models/defaultStyle';

const CustomErrorMessage: FC<CustomError> = (error: CustomError) => {
  return (
    <>
      <Paper elevation={0}>
        <div style={style.container}>
          <div style={style.title}>
            <InfoRounded />
            {error.title}
          </div>
          <div style={style.message}>{error.message}</div>
        </div>
      </Paper>
    </>
  );
};

export default CustomErrorMessage;
