import { FC, useContext, useEffect, useState } from 'react';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';
import LoginService from '../../services/LoginService';
import SettingsService from '../../services/SettingsService';
import { loginFormValues } from '../../models/Loginform';
import { ILoginServiceResponse } from '../../models/ILoginService';
import { SettingsType } from '../../models/SettingsType';
import { CustomError } from '../../models/CustomError';
import { getApolloClient } from '../../models/ApolloClient';
import { GetForm } from './Form';

const Login: FC = () => {
  const { setError, style, apiHost, authProfileID, workspaceID, environment } =
    useContext(AuthProfileContext);
  const [loading, setLoading] = useState(false);

  const initialValues: loginFormValues = {
    email: '',
    password: '',
    style: JSON.parse(style),
  };

  const onSubmit = async (formValues: loginFormValues) => {
    try {
      setLoading(true);
      const token: ILoginServiceResponse = await LoginService.login({
        apiHost: apiHost,
        userName: formValues.email,
        password: formValues.password,
        profileId: authProfileID,
        workspaceId: workspaceID,
        environment: environment,
      });

      const urlCallback = LoginService.composeRedirect(
        token,
        SettingsService.get(SettingsType.LOGIN_URL_CALLBACK),
      );
      SettingsService.setLoginSucessful(formValues.email, urlCallback, token.idTokenExpiresAt);
      window.location.replace(urlCallback);
    } catch (e: unknown) {
      const err = e as CustomError;
      setError({ title: err.title, message: err.message });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setError({ title: '', message: '' });
    getApolloClient(apiHost, workspaceID, environment);
  }, []);
  return GetForm(initialValues, onSubmit, loading);
};

export default Login;
