import { ServiceBase } from './ServiceBase';
import { gql } from '@apollo/client';
import { CustomError } from '../models/CustomError';
import { IAllowedCallbacksRequest } from '../models/IAllowedCallbacks';
import { getApolloClient } from '../models/ApolloClient';

const ALLOWED_CALLBACKS_QUERY = gql`
  query IsAllowedCallbackURL($callbackURL: String!) {
    isAllowedCallbackURL(callbackURL: $callbackURL) {
      isEnabled
    }
  }
`;

export default class AllowedCallbacksService extends ServiceBase {
  static DefaultMessage = {
    title: 'Error',
    message: 'Error trying get Allowed Callbacks. Please contact Support',
  };

  public static isAllowedCallback = async (params: IAllowedCallbacksRequest): Promise<boolean> => {
    return new Promise<boolean>(
      (
        resolve: (value: boolean | PromiseLike<boolean>) => void,
        reject: (reason?: CustomError) => void,
      ) => {
        return getApolloClient(params.apiHost, params.workspaceId, params.environment)
          .query({
            query: ALLOWED_CALLBACKS_QUERY,
            variables: {
              callbackURL: params.callbackURL,
            },
          })
          .then(response => {
            resolve(response.data.isAllowedCallbackURL.isEnabled);
          })
          .catch(error => {
            console.error(error);
            reject(this.DefaultMessage);
          });
      },
    );
  };
}
