import { CustomError } from '../models/CustomError';
import { IForgotPasswordServiceRequest, IRememberServiceRequest } from '../models/IRememberService';
import { ServiceBase } from './ServiceBase';
import { gql } from '@apollo/client';
import { getApolloClient } from '../models/ApolloClient';
import { DEFAULT_MESSAGE_FORGOT_PASSWORD, DEFAULT_MESSAGE_REMEMBER } from '../models/Constants';

const USER_REMEMBER_PASSWORD = gql`
  mutation userPasswordForgot($email: String!, $authProfileId: ID!) {
    userPasswordForgot(data: { email: $email, authProfileId: $authProfileId }) {
      success
    }
  }
`;

const USER_UPDATE_PASSWORD_FORGOT_CONFIRM = gql`
  mutation userPasswordForgotConfirm(
    $email: String!
    $authProfileId: ID!
    $password: String!
    $code: String!
  ) {
    userPasswordForgotConfirm(
      data: { email: $email, code: $code, newPassword: $password, authProfileId: $authProfileId }
    ) {
      success
    }
  }
`;

export default class RememberService extends ServiceBase {
  static DefaultMessage = {
    title: DEFAULT_MESSAGE_REMEMBER.title,
    message: DEFAULT_MESSAGE_REMEMBER.message,
  };

  static DefaultUpdatePasswordMessage = {
    title: DEFAULT_MESSAGE_FORGOT_PASSWORD.title,
    message: DEFAULT_MESSAGE_FORGOT_PASSWORD.message,
  };
  public static sendEmail = async (params: IRememberServiceRequest): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      RememberService.validateBeforeCallRememberOnServer(params, reject);
      RememberService.callServiceOnServer(params, resolve, reject);
    });
  };

  public static updatePassword = async (params: IForgotPasswordServiceRequest): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      RememberService.validateBeforeCallRememberOnServer(params, reject);
      RememberService.callServiceOnServerPassword(params, resolve, reject);
    });
  };

  private static callServiceOnServer(
    params: IRememberServiceRequest,
    resolve: (value: string | PromiseLike<string>) => void,
    reject: (reason?: CustomError) => void,
  ) {
    return getApolloClient(params.apiHost, params.workspaceId, params.environment)
      .mutate({
        mutation: USER_REMEMBER_PASSWORD,
        variables: {
          email: params.email,
          authProfileId: params.profileId,
        },
      })
      .then(response => {
        if (response.data.userPasswordForgot.success) {
          resolve(response.data.userPasswordForgot.success);
        }
        reject(this.DefaultMessage);
      })
      .catch(error => {
        console.error(error);
        reject(this.DefaultMessage);
      });
  }

  private static callServiceOnServerPassword(
    params: IForgotPasswordServiceRequest,
    resolve: (value: string | PromiseLike<string>) => void,
    reject: (reason?: CustomError) => void,
  ) {
    return getApolloClient(params.apiHost, params.workspaceId, params.environment)
      .mutate({
        mutation: USER_UPDATE_PASSWORD_FORGOT_CONFIRM,
        variables: {
          email: params.email,
          code: params.code,
          password: params.password,
          authProfileId: params.profileId,
        },
      })
      .then(response => {
        if (response.data?.userPasswordForgotConfirm?.success) {
          resolve(response.data?.userPasswordForgotConfirm?.success);
        }
        reject(this.DefaultUpdatePasswordMessage);
      })
      .catch(error => {
        console.error(error);
        reject(this.DefaultUpdatePasswordMessage);
      });
  }

  private static validateBeforeCallRememberOnServer(
    params: IRememberServiceRequest,
    reject: (reason?: CustomError) => void,
  ) {
    super.validateSettingsBaseInformation(params, reject);

    if (!params.email) {
      reject(this.DefaultMessage);
    }
  }
}
