import { createContext } from 'react';
import { CustomError } from '../models/CustomError';

export type authProfileContextType = {
  apiHost: string;
  setApiHost: (value: string) => void;
  authProfileID: string;
  setAuthProfileID: (value: string) => void;
  workspaceID: string;
  setWorkspaceID: (value: string) => void;
  environment: string;
  setEnvironment: (values: string) => void;
  authProfile: Record<string, unknown>;
  style: string;
  setStyle: (value: string) => void;
  error: CustomError;
  setError: (values: CustomError) => void;
};

const defaultContext: authProfileContextType = {
  apiHost: '',
  setApiHost: () => {},
  authProfileID: '',
  setAuthProfileID: () => {},
  workspaceID: '',
  setWorkspaceID: () => {},
  environment: '',
  setEnvironment: () => {},
  authProfile: {},
  style: '',
  setStyle: () => {},
  error: {
    title: '',
    message: '',
  },
  setError: () => {},
};

export const AuthProfileContext = createContext(defaultContext);
