import { useContext } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Button, Card, CardActions, CardContent, TextField } from '@mui/material';
import * as Yup from 'yup';
import { cardActions, cardContent, card } from '../../models/defaultStyle';
import CustomErrorMessage from '../commons/components/CustomErrorMessage';
import { routes } from '../routes';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';
import { RememberFormValues } from '../../models/Rememberform';
import { INVALID_EMAIL_ADDRESS_ERROR } from '../../models/Constants';
import { Link } from 'react-router-dom';

const validateForm = Yup.object().shape({
  email: Yup.string().email(INVALID_EMAIL_ADDRESS_ERROR).required('Required'),
});

type customFormTypes = FormikProps<RememberFormValues> & { disableSubmit: boolean };

const CustomForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  disableSubmit,
}: customFormTypes) => {
  const { error } = useContext(AuthProfileContext);
  return (
    <>
      <Form>
        <CardContent style={cardContent}>
          <TextField
            style={values.style.loginPage.card.form.field}
            fullWidth
            id="email"
            name="email"
            label="Email Address"
            disabled={disableSubmit}
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </CardContent>
        {error.title && (
          <CardContent style={cardContent}>
            <CustomErrorMessage title={error.title} message={error.message}></CustomErrorMessage>
          </CardContent>
        )}
        <CardActions style={cardActions}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={disableSubmit}
            style={cardActions.button}
          >
            Continue
          </Button>
        </CardActions>
      </Form>
      <section style={values.style.commons.redirectText.container}>
        <Link style={values.style.commons.redirectText.link} to={routes.login}>
          Go back to login screen
        </Link>
      </section>
    </>
  );
};

export function GetRememberForm(
  initialValues: RememberFormValues,
  onSubmit: (formValues: RememberFormValues) => Promise<void>,
  disableSubmit: boolean,
) {
  return (
    <Card style={card}>
      <section style={initialValues.style.commons.logo.container}>
        <img
          alt="Company logo"
          style={initialValues.style.commons.logo.img}
          src={initialValues.style.commons.logo.url}
        ></img>
      </section>

      <section style={initialValues.style.loginPage.card.title}>Forgot your password?</section>
      <section style={initialValues.style.loginPage.card.subtitle}>
        Enter your email address below. We will send you a code to reset your password
      </section>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validateForm}>
        {props => <CustomForm {...props} disableSubmit={disableSubmit} />}
      </Formik>
    </Card>
  );
}
