import { FC, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './routes/routes';
import AppContainerPage from './routes/AppContainerPage';
import Login from './routes/login/Login';
import Error from './routes/error/Error';
import { AuthProfileContext, authProfileContextType } from './contexts/AuthProfileContext';
import Signup from './routes/signup/Signup';
import { CustomError } from './models/CustomError';
import Remember from './routes/remember/Remember';
import { RememberContext, rememberContextType } from './contexts/RememberContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const App: FC = () => {
  const [apiHost, setApiHost] = useState('');
  const [authProfileID, setAuthProfileID] = useState('');
  const [workspaceID, setWorkspaceID] = useState('');
  const [environment, setEnvironment] = useState('');
  const [authProfile, setAuthProfile] = useState({});
  const [error, setError] = useState<CustomError>({ title: '', message: '' });
  const [email, setEmail] = useState('');
  const [isResend, setIsResend] = useState(false);
  const [style, setStyle] = useState('');

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Poppins',
        textTransform: 'unset',
      },
    },
  });

  useEffect(() => {
    // TODO: call to auth profile
    setAuthProfile({ authProfileID: authProfileID });
  }, [authProfileID]);

  const authProfileContext: authProfileContextType = {
    apiHost,
    setApiHost,
    authProfileID,
    setAuthProfileID,
    authProfile,
    workspaceID,
    setWorkspaceID,
    environment,
    setEnvironment,
    style,
    setStyle,
    error,
    setError,
  };

  const rememberContext: rememberContextType = {
    isResend,
    email,
    setIsResend,
    setEmail,
  };

  return (
    <BrowserRouter>
      <AuthProfileContext.Provider value={authProfileContext}>
        <RememberContext.Provider value={rememberContext}>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path={routes.home} element={<AppContainerPage />}>
                <Route path={routes.login} element={<Login />} />
                <Route path={routes.signup} element={<Signup />} />
                <Route path={routes.remember} element={<Remember />} />
                <Route path={routes.error} element={<Error />} />
              </Route>
            </Routes>
          </ThemeProvider>
        </RememberContext.Provider>
      </AuthProfileContext.Provider>
    </BrowserRouter>
  );
};

export default App;
