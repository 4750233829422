import { FC } from 'react';
import { PasswordValidators as style } from '../../models/defaultStyle';
import PasswordCheck from './PasswordCheck';
import { passwordValidators } from './Utils';

export type passwordValidationProps = {
  password?: string;
  confirmPassword?: string;
  showExtraValidation?: boolean;
};

const PasswordValidation: FC<passwordValidationProps> = ({
  password,
  confirmPassword,
  showExtraValidation = false,
}) => {
  return (
    <div>
      {password && (
        <section style={style.container}>
          <span style={style.titleSection}>Your password must contain:</span>
          <PasswordCheck
            check={password.length >= passwordValidators.min}
            text={'At least 8 characters'}
          />

          <PasswordCheck
            check={passwordValidators.lowerCase.test(password)}
            text={'Lowercase letters'}
          />

          <PasswordCheck
            check={passwordValidators.upperCase.test(password)}
            text={'Upper case letters'}
          />

          <PasswordCheck check={passwordValidators.number.test(password)} text={'Numbers'} />

          <PasswordCheck
            check={passwordValidators.spaces.test(password)}
            text={'Must not contain a leading or trailing space'}
          />
          {password && showExtraValidation && (
            <PasswordCheck check={password === confirmPassword} text={'Passwords must match'} />
          )}
        </section>
      )}
    </div>
  );
};

export default PasswordValidation;
