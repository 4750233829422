import { FC, useContext, useEffect, useState } from 'react';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';
import { RememberContext } from '../../contexts/RememberContext';
import { GetRememberForm } from './RememberForm';
import { RememberFormValues } from '../../models/Rememberform';
import RememberService from '../../services/RememberService';
import { CustomError } from '../../models/CustomError';
import { getApolloClient } from '../../models/ApolloClient';
import ConfirmPassword from './ConfirmPassword';

const Remember: FC = () => {
  const { setError, style, apiHost, authProfileID, environment, workspaceID } =
    useContext(AuthProfileContext);
  const { setIsResend, setEmail, isResend } = useContext(RememberContext);
  const [loading, setLoading] = useState(false);

  const initialValues: RememberFormValues = {
    email: '',
    style: JSON.parse(style),
  };

  const resetError = () => {
    setError({ title: '', message: '' });
  };

  const onSubmit = async (formValues: RememberFormValues) => {
    try {
      resetError();
      setLoading(true);
      await RememberService.sendEmail({
        apiHost: apiHost,
        email: formValues.email,
        profileId: authProfileID,
        workspaceId: workspaceID,
        environment: environment,
      });
      setEmail(formValues.email);
      setIsResend(true);
    } catch (e: unknown) {
      const err = e as CustomError;
      setError({ title: err.title, message: err.message });
      setEmail('');
      setIsResend(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    resetError();
    setIsResend(false);
    getApolloClient(apiHost, workspaceID, environment);
  }, []);
  return isResend ? <ConfirmPassword /> : GetRememberForm(initialValues, onSubmit, loading);
};

export default Remember;
