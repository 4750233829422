import { FC, useContext, useEffect, useState } from 'react';
import SignupService from '../../services/SignupService';
import { CustomError } from '../../models/CustomError';
import { AuthProfileContext } from '../../contexts/AuthProfileContext';
import { ILoginServiceResponse } from '../../models/ILoginService';
import { SettingsType } from '../../models/SettingsType';
import LoginService from '../../services/LoginService';
import SettingsService from '../../services/SettingsService';
import { getApolloClient } from '../../models/ApolloClient';
import SignupForm from './SignupForm';

export type SignupValues = {
  email: string;
  password: string;
  name: string;
  familyName: string;
};

const Signup: FC = () => {
  const { apiHost, authProfileID, workspaceID, environment, setError } =
    useContext(AuthProfileContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setError({
      title: '',
      message: '',
    });
    getApolloClient(apiHost, workspaceID, environment);
  }, []);

  const onSubmit = async (formValues: SignupValues) => {
    try {
      setError({ title: '', message: '' });
      setLoading(true);
      await SignupService.signup({
        apiHost: apiHost,
        userName: formValues.email,
        password: formValues.password,
        familyName: formValues.familyName,
        name: formValues.name,
        profileId: authProfileID,
        workspaceId: workspaceID,
        environment: environment,
      });
      const token: ILoginServiceResponse = await LoginService.login({
        apiHost: apiHost,
        userName: formValues.email,
        password: formValues.password,
        profileId: authProfileID,
        workspaceId: workspaceID,
        environment: environment,
      });

      const urlCallback = LoginService.composeRedirect(
        token,
        SettingsService.get(SettingsType.LOGIN_URL_CALLBACK),
      );
      SettingsService.setLoginSucessful(formValues.email, urlCallback, token.idTokenExpiresAt);
      window.location.replace(urlCallback);
    } catch (e: unknown) {
      const err = e as CustomError;
      setError({ title: err.title, message: err.message });
    } finally {
      setLoading(false);
    }
  };

  return <SignupForm onSubmit={onSubmit} disableSubmit={loading} />;
};

export default Signup;
